import { __rest } from "tslib";
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import React from 'react';
import { tv } from 'tailwind-variants';
const contentPrimitive = tv({
    base: [
        'rounded-md',
        'bg-gray-900/90',
        'px-2.5',
        'py-1.5',
        'text-xs',
        'font-medium',
        'text-gray-100',
        'data-[state=delayed-open]:motion-safe:animate-in',
        'data-[state=delayed-open]:motion-safe:fade-in',
        'data-[state=delayed-open]:motion-safe:duration-300',
        'data-[state=delayed-open]:motion-safe:ease-smooth',
        'data-[state=delayed-open]:motion-safe:slide-in-from-bottom-2',
        'data-[state=closed]:motion-safe:animate-out',
        'data-[state=closed]:motion-safe:fade-out',
        'data-[state=closed]:motion-safe:duration-200',
        'data-[state=closed]:motion-safe:ease-smooth',
        'data-[state=closed]:motion-safe:slide-out-to-bottom-2',
        'relative',
        'z-[1010]', // TODO: {tailwind} Sort this out when we move away from z-indexes in the dashboard
    ],
    variants: {},
    defaultVariants: {},
});
function Content(props) {
    const { className, sideOffset = 8 } = props, rest = __rest(props, ["className", "sideOffset"]);
    return (React.createElement(TooltipPrimitive.Content, Object.assign({ className: contentPrimitive({ className }), sideOffset: sideOffset }, rest)));
}
function BtwTooltipBase(props) {
    const { asChild = true, children, className, 'data-testid': dataTestId = 'tooltip-content', content, disableHoverableContent, sideOffset = 8, } = props;
    if (!content) {
        return React.createElement(React.Fragment, null, children);
    }
    return (React.createElement(TooltipPrimitive.Root, { disableHoverableContent: disableHoverableContent },
        React.createElement(TooltipPrimitive.Trigger, { asChild: asChild }, children),
        React.createElement(TooltipPrimitive.Portal, null,
            React.createElement(TooltipPrimitive.Content, { className: contentPrimitive({ className }), "data-testid": dataTestId, sideOffset: sideOffset }, content))));
}
const { Root } = TooltipPrimitive;
Root.defaultProps = {
    delayDuration: 0,
};
const BtwTooltip = Object.assign(BtwTooltipBase, {
    Provider: TooltipPrimitive.Provider,
    Root,
    Trigger: TooltipPrimitive.Trigger,
    Portal: TooltipPrimitive.Portal,
    Content,
});
export { BtwTooltip };
