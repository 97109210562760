import { __rest } from "tslib";
import React, { forwardRef } from 'react';
import { tv } from 'tailwind-variants';
import { baseButtonStyles } from './BtwButton';
import { Image } from './Image';
import { Stack } from './Stack';
const aspectImage = tv({
    base: [
        ...baseButtonStyles,
        'ring-1',
        'ring-inset',
        'aspect-square',
        'bg-gray-100',
        'ring-gray-600/10',
        'hover:bg-gray-200/80',
        'hover:ring-gray-600/20',
    ],
    variants: {
        size: {
            '16': ['w-16', 'rounded'],
        },
    },
    defaultVariants: {
        size: '16',
    },
});
export const AspectImage = forwardRef(function AspectImage(props, ref) {
    const { alt, size, src, className } = props, rest = __rest(props, ["alt", "size", "src", "className"]);
    return (React.createElement(Stack, { asChild: true, align: "center", justify: "center" },
        React.createElement("button", Object.assign({ className: aspectImage({ size, className }), ref: ref, type: "button" }, rest),
            React.createElement(Image, { src: src, alt: alt, className: "max-h-[80%] max-w-[80%] rounded-sm" }))));
});
