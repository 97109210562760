import { __rest } from "tslib";
import React from 'react';
import { tv } from 'tailwind-variants';
import { BtwText } from './BtwText';
const message = tv({
    base: ['py-4', 'px-5', 'rounded-lg', 'text-left'],
    variants: {
        variant: {
            gray: ['bg-gray-100', 'text-gray-800'],
            green: ['bg-green-100', 'text-green-800'],
            pink: ['bg-pink-100', 'text-pink-800'],
            red: ['bg-red-100', 'text-red-800'],
            yellow: ['bg-yellow-100', 'text-yellow-800'],
            black: ['bg-black/90', 'text-white', 'backdrop-blur-md'],
        },
    },
    defaultVariants: {
        variant: 'gray',
    },
});
function BtwMessage(props) {
    const { children, variant, className, leadingIcon } = props, rest = __rest(props, ["children", "variant", "className", "leadingIcon"]);
    return (React.createElement("div", Object.assign({ className: message({ className, variant }) }, rest),
        React.createElement(BtwText, { asChild: true, className: "flex gap-3 [&>svg]:flex-none", leading: "relaxed", size: "sm", variant: "inherit" },
            React.createElement("span", null,
                leadingIcon,
                React.createElement("span", { className: "[&>strong]:inline [&>strong]:font-semibold" }, children)))));
}
export { BtwMessage };
