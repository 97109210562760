import { __rest } from "tslib";
import * as PopoverPrimitive from '@radix-ui/react-popover';
import React from 'react';
import { tv } from 'tailwind-variants';
const basePopoverVariants = tv({
    base: [
        'ring-1',
        'shadow-xl',
        'rounded-2xl',
        'overflow-auto',
        'focus-visible:outline-none',
        'z-[1000]',
        'flex',
        'flex-col',
        'bg-white',
        'ring-gray-600/10',
        // Animation
        'data-[state=open]:motion-safe:animate-in',
        'data-[state=open]:motion-safe:fade-in-0',
        'data-[state=open]:motion-safe:zoom-in-95',
        'data-[state=closed]:motion-safe:animate-out',
        'data-[state=closed]:motion-safe:fade-out-0',
        'data-[state=closed]:motion-safe:zoom-out-95',
        'data-[side=top]:motion-safe:origin-bottom',
        'data-[side=top]:motion-safe:slide-in-from-bottom-2',
        'data-[side=right]:motion-safe:origin-left',
        'data-[side=right]:motion-safe:slide-in-from-left-2',
        'data-[side=bottom]:motion-safe:origin-top',
        'data-[side=bottom]:motion-safe:slide-in-from-top-2',
        'data-[side=left]:motion-safe:origin-right',
        'data-[side=left]:motion-safe:slide-in-from-right-2',
    ],
});
const popover = tv({
    extend: basePopoverVariants,
    base: [
        // Max available screen height depending on the position of the popover
        'max-h-[var(--radix-popover-content-available-height)]',
    ],
    variants: {
        size: {
            base: ['rounded-2xl'],
            md: ['rounded-2xl', 'p-6', 'gap-5'],
        },
        width: {
            auto: ['min-w-[var(--radix-popover-trigger-width)]'],
            trigger: ['w-[var(--radix-popover-trigger-width)]'],
        },
    },
    defaultVariants: {
        size: 'base',
        width: 'auto',
    },
});
function Content(props) {
    const { className, size, width, hasPortal = true } = props, rest = __rest(props, ["className", "size", "width", "hasPortal"]);
    return (React.createElement(WithPortal, { hasPortal: hasPortal },
        React.createElement(PopoverPrimitive.Content, Object.assign({ sideOffset: 8, collisionPadding: { top: 70, bottom: 20 }, className: popover({ className, size, width }) }, rest))));
}
function WithPortal(props) {
    const { children, hasPortal } = props;
    if (hasPortal) {
        return React.createElement(PopoverPrimitive.Portal, null, children);
    }
    return React.createElement(React.Fragment, null, children);
}
const BtwPopover = {
    Close: PopoverPrimitive.Close,
    Content,
    Root: PopoverPrimitive.Root,
    Trigger: PopoverPrimitive.Trigger,
};
export { basePopoverVariants };
export { BtwPopover };
