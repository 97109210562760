import { __rest } from "tslib";
import * as Tooltip from '@radix-ui/react-tooltip';
import React, { forwardRef } from 'react';
import { UsersIcon } from '@marvelapp/ballpark-icons';
import { BtwHeading } from '../BtwHeading';
import { BtwText } from '../BtwText';
import { Progress } from '../Progress';
import { CardSortingResponse } from '../Response/CardSorting';
import { Stack } from '../Stack';
import { Table } from '../Table';
import { cn } from '../utils';
const CardTitleAndDescription = forwardRef(function CardTitleAndDescription(props, ref) {
    const { align, className, description, title, truncate } = props, rest = __rest(props, ["align", "className", "description", "title", "truncate"]);
    return (React.createElement(Stack, Object.assign({ align: align, className: className, gap: "0.5", ref: ref }, rest),
        React.createElement(BtwHeading, { className: "max-w-full", size: "xs", title: title, truncate: truncate }, title),
        description && (React.createElement(BtwText, { className: "max-w-full", leading: "relaxed", size: "xs", title: description, truncate: truncate }, description))));
});
function AgreementBox(props) {
    const { asTableCell, agreement, className, children, 'data-testid': testid, } = props;
    const classNames = cn(className, {
        'bg-blue-50 group-hover:bg-blue-100': (agreement && agreement < 40) || agreement === 0,
        'bg-blue-100 group-hover:bg-blue-200': agreement && agreement >= 40,
        'bg-blue-200 group-hover:bg-blue-300': agreement && agreement >= 60,
        'bg-blue-300 group-hover:bg-blue-400': agreement && agreement >= 80,
        'text-blue-900': agreement || agreement === 0,
    });
    if (asTableCell) {
        return (React.createElement(Table.Td, { className: classNames, "data-testid": testid },
            children,
            ' '));
    }
    return (React.createElement(Stack, { align: "center", className: classNames, justify: "center", width: "full", "data-testid": testid }, children));
}
function HoverBox(props) {
    const { children, className } = props, rest = __rest(props, ["children", "className"]);
    return (React.createElement("div", Object.assign({ className: cn('invisible', 'absolute', 'top-0', 'z-10', 'min-h-full', 'w-[150%]', 'rounded-md', 'bg-white', 'p-2.5', 'opacity-0', 'shadow-lg', 'ring-1', 'ring-gray-600/10', 'group-hover:visible', 'group-hover:opacity-100', className) }, rest), children));
}
const AccordionTableRoot = forwardRef(function AccordionTableRoot(props, ref) {
    const { children, headings, type = 'outer' } = props, rest = __rest(props, ["children", "headings", "type"]);
    return (React.createElement(Stack, Object.assign({ width: "full", ref: ref, gap: type === 'inner' ? '5' : '0' }, rest),
        React.createElement(Stack, { gap: "8", direction: "row", asChild: true, width: "full" },
            React.createElement("header", { className: cn('[&>*]:flex-1', {
                    'px-2': type === 'outer',
                    'py-4': type === 'outer',
                    'border-b': type === 'outer',
                    'border-gray-600/20': type === 'outer',
                }) }, headings.map((heading) => (React.createElement(BtwHeading, { key: heading, size: "xs", weight: type === 'inner' ? 'medium' : 'semibold' }, heading))))),
        children));
});
function AccordionTableInner(props) {
    return (React.createElement(Stack, { width: "full", gap: "5", className: props.className }, props.children));
}
const AccordionTableRow = forwardRef(function AccordionTableRow(props, ref) {
    const { children, className } = props, rest = __rest(props, ["children", "className"]);
    return (React.createElement(Stack, Object.assign({ className: cn('[&>*]:flex-1', className), direction: "row", gap: "8", ref: ref, width: "full" }, rest), children));
});
function ColPrimary(props) {
    return (React.createElement(BtwText, { className: props.className, size: "xs", truncate: true, variant: "primary", weight: "medium", "data-testid": props['data-testid'] }, props.children));
}
function ColSecondary(props) {
    return (React.createElement(BtwText, { truncate: true, className: props.className, weight: "normal", size: "xs", "data-testid": props['data-testid'] }, props.children));
}
function ColUsers(props) {
    return (React.createElement(BtwText, { align: "left", className: cn('flex', 'flex-1', 'items-center', 'gap-1', props.className), size: "xs", truncate: true, weight: "normal" },
        React.createElement("div", { className: "[&>svg>path]:stroke-1" },
            React.createElement(UsersIcon, { className: "block h-3.5 w-3.5" })),
        props.children));
}
function ColProgress(props) {
    const { agreement, className } = props;
    return (React.createElement(Stack, { direction: "row", align: "center", gap: "2.5", className: className },
        React.createElement(Progress.Animated, { variant: getAgreementColor(agreement), size: "sm", value: agreement }),
        React.createElement("div", null,
            React.createElement(BtwText, { className: "w-[46px] text-left", font: "mono", size: "xs", variant: getAgreementColor(agreement), weight: "medium", "data-testid": props['data-testid'] },
                agreement,
                "%"))));
}
/**
 * Agreement table
 */
function AgreementTableRoot(props) {
    const { categoriesCount, children } = props;
    return (React.createElement(CardSortingResponse.Root, { type: categoriesCount > 4 ? 'always' : 'hover' },
        React.createElement("div", { className: "pb-8 pr-8" },
            React.createElement(Table, { minWidth: categoriesCount * 150, className: "border-separate border-spacing-1", "data-testid": props['data-testid'] }, children))));
}
function AgreementTableHeaderItem(props) {
    const { children } = props;
    return (React.createElement("div", { className: "p-1" },
        React.createElement(Stack, { align: "center", className: "h-9", justify: "center" },
            React.createElement(BtwHeading, { size: "xs", truncate: true }, children))));
}
function AgreementTableCell(props) {
    const { align = 'start', agreement = null, className, description, title, } = props;
    const hasAgreement = agreement !== null && agreement !== undefined;
    return (React.createElement(AgreementBox, { agreement: agreement, asTableCell: true, className: cn('group', 'relative', 'rounded-md', 'py-2.5', className), "data-testid": props['data-testid'] }, (title || hasAgreement) && (React.createElement(Tooltip.Root, null,
        React.createElement(Tooltip.Trigger, { asChild: true },
            React.createElement(CardTitleAndDescription, { align: align, className: "w-full", description: description, title: title || (agreement !== 0 ? `${agreement}%` : ''), truncate: true })),
        !hasAgreement && title && title.length > 12 && (React.createElement(Tooltip.Portal, null,
            React.createElement(Tooltip.Content, { align: align, className: cn('-mb-[26px]', 'rounded-md', 'bg-white', 'p-2.5', 'shadow-lg', 'ring-1', 'max-w-md', 'ring-gray-600/10', {
                    '-ml-2.5': align === 'start',
                }) },
                React.createElement(CardTitleAndDescription, { align: align, className: "w-full", description: description, title: title || `${agreement}` }))))))));
}
const getAgreementColor = (value) => {
    if (value < 40)
        return 'red';
    if (value < 70)
        return 'yellow';
    return 'green';
};
/**
 * Similarity table
 */
function SimilarityTableRow(props) {
    const { title, similarity } = props;
    return (React.createElement(Stack, { align: "center", direction: "row", width: "full", "data-testid": "similarity-matrix-row", className: "group relative" },
        similarity.map((value, index) => (
        // eslint-disable-next-line react/no-array-index-key
        React.createElement("div", { className: "p-1", key: index },
            React.createElement(AgreementBox, { className: "h-10 w-10 rounded-md", agreement: value, "data-testid": "similarity-matrix-value" },
                React.createElement(BtwText, { variant: "current", weight: "medium", size: "xs" }, value))))),
        React.createElement(Stack, { align: "center", direction: "row", className: "group relative h-10 min-w-0 flex-1 p-1", "data-testid": "similarity-matrix-card" },
            React.createElement(BtwText, { className: "max-w-full", size: "xs", truncate: true }, title),
            title && title.length > 12 && (React.createElement(HoverBox, { className: "left-0 -ml-1.5 max-w-full" },
                React.createElement(BtwText, { align: "left", className: "w-full pt-0.5", size: "xs" }, title))))));
}
const AccordionTable = {
    ColPrimary,
    ColProgress,
    ColSecondary,
    ColUsers,
    Inner: AccordionTableInner,
    Root: AccordionTableRoot,
    Row: AccordionTableRow,
};
const CardSortingAgreementTable = {
    Root: AgreementTableRoot,
    HeaderItem: AgreementTableHeaderItem,
    Cell: AgreementTableCell,
};
const CardSortingSimilarityTable = {
    Row: SimilarityTableRow,
};
export { AccordionTable, CardSortingAgreementTable, CardSortingSimilarityTable, };
